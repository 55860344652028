import { useState } from "react";
import useForm from "../../../../hooks/useForm";
import { LegsInfo } from "./LegsInfo";
import { General } from "./General";

const JobInfo = ({ job = {}, form, ...props }) => {
  const legForm = useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  function showCreateModal(vis) {
    if (vis === false) {
      setCreateModalVisible(vis);
      return;
    }
    setCreateModalVisible(true);
  }

  return (
    <>
      {/* {job.status !== getStage(1).title && !props.editing && (
        <StatusTimeline status={job?.status} timeline={timelineMap} />
      )} */}
      <General job={job} {...form} {...props} />
      <LegsInfo
        {...form}
        {...props}
        legForm={legForm}
        job={job}
        status={job.status}
        setModalVisible={showCreateModal}
        modalVisible={createModalVisible}
      />
    </>
  );
};

export default JobInfo;
