// export const jobStages = {
//   1: {
//     order: 1,
//     id: "new",
//     title: "New",
//     next: "pending",
//     button: "Publish",
//     color: "teal",
//   },
//   2: {
//     order: 2,
//     id: "pending",
//     title: "Pending",
//     next: "booked",
//     button: "Book",
//     color: "orange",
//   },
//   3: {
//     order: 3,
//     id: "booked",
//     title: "Booked",
//     next: "enRoute",
//     button: "Start Job",
//     color: "yellow",
//   },
//   4: {
//     order: 4,
//     id: "enRoute",
//     title: "En Route",
//     next: "delivered",
//     button: "Mark Delivered",
//     color: "blue",
//   },
//   5: {
//     order: 5,
//     id: "delivered",
//     title: "Delivered",
//     next: "invoiced",
//     color: "cyan",
//   },
//   6: {
//     order: 6,
//     id: "invoiced",
//     title: "Invoiced",
//     next: "paid",
//     color: "purple",
//   },
//   7: { order: 7, id: "paid", title: "Paid" },
// };
export const jobStages = {
  1: {
    order: 1,
    id: "new",
    title: "New",
    next: "pending",
    button: "Publish",
  },
  2: {
    order: 2,
    id: "pending",
    title: "Pending",
    next: "enRoute",
    button: "Start Job",
    color: "orange",
  },
  3: {
    order: 3,
    id: "enRoute",
    title: "En Route",
    next: "delivered",
    button: "Mark Delivered",
    color: "blue",
  },
  4: {
    order: 4,
    id: "delivered",
    title: "Delivered",
    next: "invoiced",
    // button: "Create Invoice",
    // modal: "Are you sure you want to create an invoice from this job?",
    color: "cyan",
  },
  5: {
    order: 5,
    id: "invoiced",
    title: "Invoiced",
    color: "purple",
  },
};

export const getStage = (key) => {
  let res = {};
  if (typeof key === "string") {
    const current = Object.keys(jobStages).find(
      (i) => jobStages[i].id === key || jobStages[i].title === key
    );
    res = jobStages[current] || {};
  } else {
    res = jobStages[key] || {};
  }
  return res;
};

export const getNextStage = (key) => {
  if (typeof key === "string") {
    const current = Object.keys(jobStages).find((i) => jobStages[i].id === key);
    if (!jobStages[current].next) {
      return null;
    }
    return getStage(Number(jobStages[current].order) + 1);
  }
  return getStage(Number(key) + 1);
};
