import { useSearchParams } from "react-router-dom";

export const useQueryNavigation = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function setParam(e) {
    setSearchParams(e);
  }

  function getParam(id) {
    return searchParams.get(id);
  }

  return { setParam, getParam, params: searchParams };
};
