import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  deleteUsers,
  selectAllUsers,
  resetUsers,
} from "../../../redux/reducers/users/usersSlice";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UsersAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const usersStatus = useSelector((state) => state.users.status);
  const usersError = useSelector((state) => state.users.status);
  const { user } = useAuth();

  useEffect(() => {
    if (usersStatus === "idle") {
      dispatch(
        fetchUsers({
          parent: user.parentCompany,
          admin: true,
          fullParent: true,
        })
      );
    }
  }, [usersStatus, dispatch, user.parentCompany]);

  useEffect(() => {
    return () => dispatch(resetUsers());
  }, []);

  function handleEdit(itemId) {
    navigate(`/users/${itemId}`);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;
    const res = await dispatch(
      deleteUsers({ data: items, parentId: parentCompany })
    ).unwrap();

    if (res.status === "success") {
      toast("Users Deleted", {
        type: "success",
        toastId: "usersDeleted",
      });
    }

    return res;
  }

  return (
    <TableLayout
      id="users"
      loading={usersStatus === "loading"}
      columns={COLUMN_DEFINITIONS}
      data={users}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );
};

export default UsersAdmin;
