import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils";

const CrumbsContext = createContext();

export const CrumbsProvider = ({ children }) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]);
  const [crumbsVisible, setCrumbsVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  // const crumbData = useMemo(() => {
  //   return renderBreadcrumbs(location.pathname);
  // }, [location.pathname]);

  useEffect(() => {
    const crumbs = renderBreadcrumbs(location.pathname);
    setCrumbs(crumbs);
  }, [location.pathname]);

  const updateCrumbName = (data) => {
    const old = crumbs;
    const found = old.findIndex((i) => data.path === i.path);

    old[found] = data;

    // const newCrumbs = old.map((i) => {
    //   if (data.path === i.path) {
    //     return data;
    //   } else {
    //     return i;
    //   }
    // });
    setCrumbs(old);
    return;
  };

  const value = useMemo(
    () => ({
      crumbsVisible,
      setCrumbsVisible,
      crumbs,
      setCrumbs,
      updateCrumbName,
      loading,
      setLoading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [crumbs.length, loading, location.pathname]
  );

  return (
    <CrumbsContext.Provider value={value}>{children}</CrumbsContext.Provider>
  );
};

export const useCrumbs = () => {
  return useContext(CrumbsContext);
};

function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

export function renderBreadcrumbs(str) {
  const data = [
    {
      path: "/",
      name: "Home",
    },
  ];

  if (str === "/") {
    return data;
  }

  const arr = str.split("/");

  let parentPath = "";

  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];
    if (isEmptyOrSpaces(key)) {
      continue;
    }
    const path = parentPath + `/${key}`;
    data.push({
      path,
      name: capitalizeFirstLetter(key),
    });
    parentPath = path;
  }

  return data;
}
