import classNames from "classnames";
import React from "react";

const Timeline = (props) => {
  function renderItems(arr) {
    const timelineItems = arr;
    return timelineItems.map((item, i) => {
      const classes = classNames(
        { "mb-10 ml-4": props.vertical || !props.inline },
        { "relative mb-6 mb-0": props.inline }
      );
      return props.layout ? (
        props.layout(item, i, arr)
      ) : (
        <div className={classes}>
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
          <>
            <time className="mb-1 text-sm font-normal leading-none text-gray-400">
              {item.date}
            </time>
            <h3 className="text-lg font-semibold text-gray-900 ">
              {item.title}
            </h3>
            {item.body}
          </>
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 border-white"></div>
        </div>
      );
    });
  }

  const wrapperClasses = classNames(
    "grid mr-10",
    `grid-cols-${props.timelineLength || props.timeline.length + 1}`,
    props.className
  );

  return (
    <div className={wrapperClasses}>
      {/* <ol className={wrapperClasses}>{renderItems(props.timeline)}</ol> */}
      {renderItems(props.timeline)}
    </div>
  );
};

export default Timeline;
