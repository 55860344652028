import classNames from "classnames";
import React from "react";

export const Card = (props) => {
  const classes = classNames(
    "p-3 w-full bg-white  border border-gray-200 shadow-sm",
    props.className,
    {
      "rounded-lg": !props.header,
      "rounded-b-lg": props.header,
    }
  );

  const mainCard = (
    <div className={classes}>{props.children || props.content}</div>
  );

  return props.header ? (
    <div className={props.className}>
      {props.header}
      {mainCard}
    </div>
  ) : (
    mainCard
  );
};

export default Card;
