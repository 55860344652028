import React, { createContext, useContext, useEffect, useState } from "react";
import request from "../utils/request";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";
import { isObject } from "../utils";

const contextDefaultValues = {
  configs: {},
  addConfig: () => {},
  deleteConfig: () => {},
};

export const ConfigContext = createContext(contextDefaultValues);

const ConfigProvider = (props) => {
  const [configs, setConfigs] = useState(props.configJson);
  const { user } = useAuth();
  const parentCompany = isObject(user.parentCompany)
    ? user.parentCompany._id
    : user.parentCompany;

  useEffect(() => {
    (async () => {
      if (!configs && user.active) {
        try {
          let configData = await request(`/config/${parentCompany}?`);

          if (!configData.data.config) {
            // const fullUser = await request(`/account/current?fullUser=true`);
            configData = await request(`/config/${parentCompany}?type=parent`, {
              method: "post",
              data: {},
            });
          }

          const {
            data: { config },
          } = configData;

          setConfigs(config ? config.configs : {});
        } catch (err) {}
      }
    })();
  }, [user.active]);

  const addConfig = async (newConfigItem) => {
    const newConfig = await request(`/config/${parentCompany}`, {
      method: "put",
      data: newConfigItem,
    });

    if (newConfig.data.status === "success") {
      setConfigs(newConfig.data.config.configs);
      toast(newConfig.data.message, {
        toastId: "addConfig.hook." + new Date().toISOString(),
        type: newConfig.data.status,
      });
    }
  };

  const deleteConfig = async (configData) => {
    const configDelete = await request(`/config/${parentCompany}`, {
      method: "delete",
      data: configData,
    });

    if (configDelete.data.status === "success") {
      setConfigs(configDelete.data.config.configs);
      toast(configDelete.data.message, {
        toastId: "deleteConfig.hook",
        type: configDelete.data.status,
      });
    }
  };

  return (
    <ConfigContext.Provider
      value={{ configs: configs || {}, addConfig, deleteConfig }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};

export default ConfigProvider;
