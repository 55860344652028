import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks/useAuth";
import Input from "../../../shared/Input";
import useForm from "../../../hooks/useForm";
import { Button, Modal } from "../../../shared";
import { addNewCustomer } from "../../../redux/reducers/customers/customerSlice";
import { useAddCustomerMutation } from "../../../redux/services/customers/customersApi";

export const CreateCustomerModal = ({ visible, setVisible, onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { form, updateForm, clearForm } = useForm();
  const [loading, setLoading] = useState(false);

  const [addCustomer, { isLoading }] = useAddCustomerMutation();

  function closeModal() {
    clearForm();
    onCancel && onCancel(false);
    setLoading(false);
    setVisible(false);
  }

  async function handleSave() {
    setLoading(true);
    const res = await addCustomer(form).unwrap();

    setLoading(false);
    toast(res.message, {
      toastId: "add-customer-" + res.status,
      type: res.status,
    });

    if (res.status === "success") {
      navigate("/admin/customers/" + res.data._id);
      closeModal();
    }
    // Add logic for what to do when user already exists
    setLoading(false);
  }

  const footer = (
    <div>
      <Button type="alternative" className="mr-2" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        id="submit-customer"
        loading={isLoading}
        disabled={isLoading}
        onClick={handleSave}
      >
        Submit
      </Button>
    </div>
  );

  const content = (
    <div className="grid grid-cols-1 gap-3">
      <div>
        <Input
          id="name"
          value={form.name}
          label="Company Name"
          placeholder="Company Name"
          onChange={updateForm}
        />
      </div>
      <div>
        <Input
          id="email"
          value={form.email}
          label="Email"
          placeholder="Email"
          onChange={updateForm}
        />
      </div>
      <div>
        <Input
          id="phone"
          value={form.phone}
          label="Phone"
          placeholder="Phone"
          onChange={updateForm}
        />
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={closeModal}
      title="Create Customer"
      footer={footer}
      content={content}
      size="sm"
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
