import classNames from "classnames";
import React, { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useFloating, autoUpdate, flip, shift } from "@floating-ui/react-dom";

const Dropdown = ({ widthClasses = "w-56", ...props }) => {
  const dropRef = useRef();
  const navigate = useNavigate();
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [
      flip({ allowedPlacements: ["bottom-start", "bottom-end"] }),
      shift(),
    ],
  });

  const [hasBeenInit, setHasBeenInit] = useState(false);

  function handleClick(item, i) {
    props.itemClick && props.itemClick(item);
    item.onClick ? item.onClick(item) : navigate(item.path);
  }

  function renderItem(item, i) {
    const title = item.title || item.label;
    return (
      <Menu.Item as="div" key={title + i}>
        {({ active }) => {
          const itemClasses = classNames(
            props.itemClasses,
            "group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer",
            {
              "bg-primary-600 text-white": active,
              "text-gray-900": !active,
              "!text-gray-600 !bg-none !cursor-not-allowed": item.disabled,
            }
          );
          return (
            <div
              onClick={item.disabled ? null : () => handleClick(item)}
              className={itemClasses}
            >
              <span className="mr-1.5">{item.icon}</span>
              {typeof title === "function" ? title({ item, active }) : title}
            </div>
          );
        }}
      </Menu.Item>
    );
  }

  const wrapperClasses = classNames(
    "relative h-full w-full inline-block text-left",
    props.className
  );

  const dataWrapperClasses = classNames(
    "absolute mt-2 rounded-md z-10",
    "bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
    widthClasses,
    {
      "origin-top-right right-0": props.align === "right" || !props.align,
      "origin-top-left left-0": props.align === "left",
    },
    props.dataWrapperClassName
  );

  const trigger = React.Children.map([props.trigger], (child, index) => {
    const childProps = { ...child.props, className: "" }; //<Menu.Button {...child.props}>{}</Menu.Button>;
    let as;

    if (typeof child.type === "function") {
      as = React.Fragment;
    }

    return (
      <Menu.Button
        ref={(el) => {
          if (props.defaultOpen && !hasBeenInit) {
            setHasBeenInit(true);
            el?.click();
          }
          refs.setReference(el);
        }}
        // ref={refs.setReference}
        as="div"
        {...childProps}
      >
        {child}
      </Menu.Button>
    );
  });

  function renderData(dropData, close) {
    return Array.isArray(dropData) ? (
      <div key="2" className="py-1 w-full" role="none">
        {dropData.map((item) => renderItem(item))}
      </div>
    ) : typeof dropData === "function" ? (
      dropData(close)
    ) : (
      dropData
    );
  }

  return (
    <Menu as="div" className={wrapperClasses}>
      {({ open, close }) => {
        return (
          <>
            {trigger}
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={refs.setFloating}
                as="div"
                className={dataWrapperClasses}
                style={floatingStyles}
              >
                <div ref={dropRef} className="px-1 py-1">
                  {props.data && renderData(props.data, close)}
                </div>
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default Dropdown;
