import React, { useContext } from "react";
import RightContent from "./RightContent";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { LayoutContext } from "../../layouts/Root";

const Header = (props) => {
  const { navOpen, setNavOpen } = useContext(LayoutContext);
  // "py-5 bg-gray-700 text-white text-center"
  // "flex flow-row flex-initial justify-between items-center"
  // "px-0 py-0 h-10 bg-white border-b-[1px]"

  const headerWrapClass =
    "px-0 py-0 h-10 bg-white border-b-[1px] flex  justify-between";
  // flow-row flex-initial
  return (
    <header className={headerWrapClass}>
      <div className="flex items-center px-3">
        <Bars3Icon
          className="w-7 h-7 md:hidden cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
        {props.leftContent}
      </div>
      <nav className="flex flex-row items-center h-full">
        <RightContent>{props.rightContent}</RightContent>
      </nav>
    </header>
  );
};

export default Header;
