import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { jobUpdated } from "../../redux/reducers/jobs/jobsSlice";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { updateJob } from "../../redux/reducers/jobs/jobSlice";
import CreateModal from "./CreateModal";
import { useTranslation } from "react-i18next";
import {
  useGetJobsQuery,
  useUpdateJobMutation,
} from "../../redux/services/jobs/jobsApi";
import { usePrefetch } from "../../redux/api";
import { useDebounceSearch } from "../../hooks";
import { DeleteJobModal } from "./DeleteModal";
import { ClientsFilterList, StatusFilterList } from "../../components";
import { removeEmptyValues } from "../../utils";
import { isAuthorized } from "../../utils/authority";
import { toast } from "react-toastify";

const Jobs = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deselectItems, setDeselectItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const sendSelectedItems = useCallback((value) => setSelectedItems(value), []);

  const { search, handleSearch } = useDebounceSearch();

  const [filters, setFilters] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const searchFilters = filters.reduce((a, v) => {
    if (v.id === "transactionDate") {
      return {
        ...a,
        startDate: v.value?.start.toISOString(),
        endDate: v.value?.end.toISOString(),
      };
    }
    return { ...a, [v.id]: v.value };
  }, {});

  const { data, isLoading, isFetching } = useGetJobsQuery({
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
    ...removeEmptyValues(searchFilters),
  });
  const [updateJob, { isLoading: jobUpdating, ...restUpdateJob }] =
    useUpdateJobMutation();

  const prefetchPage = usePrefetch("getJobs");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
      ...removeEmptyValues(searchFilters),
    });
  }, [
    prefetchPage,
    data?.nextPage,
    pageSize,
    search.useSearch,
    search.searchTerm,
  ]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  function handleEdit(itemId) {
    navigate(`/jobs/${itemId}`);
  }

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updateJob(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: `update-job-${res.status}`,
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  function handleDeleteClick() {
    setDeleteVisible(true);
  }

  const cannotCreateOrEdit = !isAuthorized("owner, admin");
  // user.authority === "client" || user.authority === "user";

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  const filterTypes = useMemo(
    () =>
      [
        {
          id: "transactionDate",
          type: "date",
          label: "Transaction Date",
          defaultText: "All Time",
          defaultValue: {
            id: "allTime",
            value: null,
          },
        },
        {
          id: "status",
          type: "multiple",
          label: "Status",
          defaultText: "All",
          content: StatusFilterList,
        },
        {
          id: "client",
          remove: !isAuthorized("owner, admin, user, driver"),
          type: "multiple",
          label: "Client",
          defaultText: "All Clients",
          content: ClientsFilterList,
        },
      ].filter((i) => !i.remove),
    []
  );

  return (
    <div className="">
      <TableLayout
        id="jobs"
        title={t("jobs.main")}
        buttonSize="xs"
        loading={isLoading}
        columns={COLUMN_DEFINITIONS}
        data={data?.jobs}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        globalSearchFilters={["transactionDate", "loadNumber"]}
        fullTextSearch={globalFilter}
        sendSelectedItems={sendSelectedItems}
        handleDeleteClick={handleDeleteClick}
        clearSelectedItems={deselectItems}
        filters={filterTypes}
        onFiltersChange={setFilters}
        hideCheckbox={!isAuthorized("owner,admin")}
      />
      <CreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
      <DeleteJobModal
        deselectItems={() => {
          setDeselectItems(true);
          setTimeout(() => setDeselectItems(false), 500);
        }}
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        jobs={selectedItems}
      />
    </div>
  );
};

export default Jobs;
