import React, { useState } from "react";
import Checkbox from "../Checkbox";
import Select from "../Select";

const MultiSelect = ({ id, onChange, options = [], children, ...props }) => {
  const [selectValue, setSelectValue] = useState({
    label: "Select an option",
    value: "",
  });
  const [selected, setSelected] = useState([]);

  function handleChange(e) {
    const newValue = e;
    const checked = selected.findIndex((v) => {
      return v.value === e.value;
    });

    if (checked >= 0) {
      const newArray = selected.filter((item) => item.value !== e.value);
      setSelected(newArray);
      onChange && onChange(newArray);
      if (newArray.length === 0) {
        onChange && onChange([]);
        return setSelectValue({ label: "Select an option", value: "" });
      }
    } else {
      const newSelected = [...selected, newValue];
      setSelected(newSelected);
      onChange && onChange(newSelected);
    }
  }

  function renderOptions() {
    return options.map((item, i) => {
      const checked = selected.filter((v) => v.value === item.value).length > 0;

      return {
        label: (
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={checked}
              name={item.label}
              sizeclassName="w-4 h-4"
            />
            <span>{item.label}</span>
          </div>
        ),
        value: item.value,
        id: item.id,
      };
    });
  }

  const label = selected
    .map((item) => item.label)
    .filter((item) => item !== "");

  return (
    <div className="w-full">
      <Select
        id={id}
        onChange={handleChange}
        options={renderOptions()}
        value={selectValue.label}
        label={
          props.label
            ? props.label
            : label.length === 0
            ? null
            : label.join(", ")
        }
      />
      {children &&
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              selected,
              onChange: handleChange,
            });
          }
          return child;
        })}
    </div>
  );
};

export default MultiSelect;
