import classNames from "classnames";
import React, { Children, cloneElement } from "react";

const Form = (props) => {
  const wrapperClasses = classNames(props.className);
  const childClasses = classNames(props.childClassName, "w-full");
  return (
    <div className={wrapperClasses}>
      {Children.map(props.children, (child, i) => {
        let el = cloneElement(child, {});
        return <div className={childClasses}>{el}</div>;
      })}
    </div>
  );
};

export default Form;
