import { Input, DatePicker } from "../../../../shared";
import { formatDate, parseFixed } from "../../../../utils";
import classNames from "classnames";
import { useUpdateJobRatesMutation } from "../../../../redux/services/jobs/jobsApi";
import { isAuthorized } from "../../../../utils/authority";

export const General = ({
  form,
  updateForm,
  editing,
  isNew,
  loading,
  setLoading,
}) => {
  const [updateJobRates] = useUpdateJobRatesMutation();

  async function handleSave({ target }, charge) {
    setLoading(true);
    const data = { [target.id]: target.value, charge, id: target.id };
    const res = await updateJobRates({ id: form._id, data }).unwrap();
    setLoading(false);
  }

  const classes = classNames("grid gap-2", {
    "grid-cols-3 xl:grid-cols-6": editing || isNew,
    "grid-cols-3 lg:grid-cols-8": !isNew && !editing,
  });

  console.log(form.fuelRate);

  return (
    <div className="mt-2 mb-5">
      <div className={classes}>
        {isAuthorized("owner,admin") &&
          (!form.useFlatRate ? (
            <>
              <InputOnOff
                loading={loading}
                id="fuelRate"
                placeholder="Fuel Rate"
                label="Fuel Rate"
                charge="fuelSurcharge"
                value={form.fuelRate}
                status={form.status}
                edit={editing || isNew}
                type="amount"
                updateForm={updateForm}
                onBlur={(e) => handleSave(e, "fuelSurcharge")}
              />
              <InputOnOff
                loading={loading}
                id="baseRate"
                placeholder="Base Rate"
                label="Base Rate"
                charge="baseCharge"
                value={form.baseRate}
                status={form.status}
                edit={editing || isNew}
                type="amount"
                updateForm={updateForm}
                onBlur={(e) => handleSave(e, "baseCharge")}
              />
            </>
          ) : (
            <InputOnOff
              loading={loading}
              id="flatRate"
              placeholder="Flat Rate"
              label="Flat Rate"
              value={form.flatRate}
              status={form.status}
              edit={editing || isNew}
              type="amount"
              updateForm={updateForm}
              onBlur={(e) => handleSave(e, "baseCharge")}
            />
          ))}
        <div>
          <InputOnOff
            loading={loading}
            label="Transaction Date"
            status={form.status}
            edit={!isAuthorized("owner,admin") ? false : editing || isNew}
            value={formatDate(form.transactionDate, null, true)}
            updateForm={updateForm}
          >
            <DatePicker
              id="transactionDate"
              value={formatDate(form.transactionDate, null, true)}
              onChange={(e) => {
                updateForm({ value: e.value, id: "transactionDate" });
                handleSave({
                  target: { value: e.value, id: "transactionDate" },
                });
              }}
              label="Transaction Date"
              placeholder="Select a driver"
            />
          </InputOnOff>
        </div>
      </div>
    </div>
  );
};

const InputOnOff = ({
  id,
  value,
  placeholder = "",
  type,
  label,
  edit,
  children,
  updateForm,
  onBlur,
  loading = false,
}) => {
  return edit ? (
    children ? (
      children
    ) : (
      <Input
        loading={loading}
        type="number"
        step="0.05"
        min="0"
        onBlur={onBlur}
        onChange={(e) =>
          updateForm({
            id: "flatRate",
            value: parseFixed(e.value),
          })
        }
        icon={{
          left: <div className="text-gray-500 text-sm">$</div>,
        }}
        id={id}
        value={value}
        placeholder={placeholder}
        label={label}
      />
    )
  ) : (
    <div>
      <div className="mb-2 text-sm font-medium text-gray-800">{label}</div>
      <div className="text-lg font-bold">
        {type === "amount" && "$"} {value || (type === "amount" ? 0 : null)}
      </div>
    </div>
  );
};
